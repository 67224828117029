@font-face {
    font-family: 'Rawline';
    font-style: normal;
    font-weight: 400;
    src: url('../../fonts/rawline-400.woff') format('woff');
  }
  

  @font-face {
    font-family: 'Rawline';
    font-style: italic;
    font-weight: 400;
    src: url('../../fonts/rawline-400i.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Rawline';
  font-style: normal;
  font-weight: 100;
  src: url('../../fonts/rawline-100.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Rawline';
  font-style: italic;
  font-weight: 100;
  src: url('../../fonts/rawline-100i.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Rawline';
  font-style: normal;
  font-weight: 200;
  src: url('../../fonts/rawline-200.woff') format('woff');
  }
  

  @font-face {
    font-family: 'Rawline';
    font-style: italic;
    font-weight: 200;
    src: url('../../fonts/rawline-200i.woff') format('woff');
  }
  

  @font-face {
    font-family: 'Rawline';
    font-style: normal;
    font-weight: 300;
    src: url('../../fonts/rawline-300.woff') format('woff');
  }
  

  @font-face {
    font-family: 'Rawline';
    font-style: italic;
    font-weight: 300;
    src: url('../../fonts/rawline-300i.woff') format('woff');
  }
  

  @font-face {
    font-family: 'Rawline';
    font-style: normal;
    font-weight: 500;
    src: url('../../fonts/rawline-500.woff') format('woff');
  }
  

  @font-face {
    font-family: 'Rawline';
    font-style: italic;
    font-weight: 500;
    src: url('../../fonts/rawline-500i.woff') format('woff');
  }
  

  @font-face {
    font-family: 'Rawline';
    font-style: normal;
    font-weight: 600;
    src: url('../../fonts/rawline-600.woff') format('woff');
  }
  

  @font-face {
    font-family: 'Rawline';
    font-style: italic;
    font-weight: 600;
    src: url('../../fonts/rawline-600i.woff') format('woff');
  }
  

  @font-face {
    font-family: 'Rawline';
    font-style: normal;
    font-weight: 700;
    src: url('../../fonts/rawline-700.woff') format('woff');
  }
  

  @font-face {
    font-family: 'Rawline';
    font-style: italic;
    font-weight: 700;
    src: url('../../fonts/rawline-700i.woff') format('woff');
  }
  

  @font-face {
    font-family: 'Rawline';
    font-style: normal;
    font-weight: 800;
    src: url('../../fonts/rawline-800.woff') format('woff');
  }
  

  @font-face {
    font-family: 'Rawline';
    font-style: italic;
    font-weight: 800;
    src: url('../../fonts/rawline-800i.woff') format('woff');
  }
  

  @font-face {
    font-family: 'Rawline';
    font-style: normal;
    font-weight: 900;
    src: url('../../fonts/rawline-900.woff') format('woff');
  }
  

  @font-face {
    font-family: 'Rawline';
    font-style: italic;
    font-weight: 900;
    src: url('../../fonts/rawline-900i.woff') format('woff');
  }

:root {
  --font-family-base: 'Rawline', Raleway, sans-serif;
}