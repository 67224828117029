.cookie-fs-modal-cookie-site-experience-value {
  display: flex;
  align-items: center;
}

.cookie-fs-modal-cookie-site-experience-title {
  font-size: var(--font-size-scale-up-01);
  font-weight: 600;
  font-weight: var(--font-weight-bold);
}

.cookie-fs-modal-cookie-paragraph-no-margin {
  margin: 0;
}
