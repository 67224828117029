:root {
  --green-cool-vivid-80: #19311E;
  --green-cool-vivid-70: #154C21;
  --green-cool-vivid-60: #216E1F;
  --green-cool-vivid-50: #168821;
  --green-cool-vivid-40: #00A91C;
  --green-cool-vivid-30: #21C834;
  --green-cool-vivid-20: #70E17B;
  --green-cool-vivid-10: #B7F5BD;
  --green-cool-vivid-5: #E3F5E1;
}