:root {
  --red-vivid-80: #5C1111;
  --red-vivid-70: #8B0A03;
  --red-vivid-60: #B50909;
  --red-vivid-50: #E52207;
  --red-vivid-40: #FB5A47;
  --red-vivid-30: #FF8D7B;
  --red-vivid-20: #FDB8AE;
  --red-vivid-10: #FDE0DB;
  --red-vivid-5: #FFF3F2;
}