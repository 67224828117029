:root {
  --gray-90: #1B1B1B;
  --gray-80: #333333;
  --gray-70: #555555;
  --gray-60: #636363;
  --gray-50: #757575;
  --gray-40: #888888;
  --gray-30: #ADADAD;
  --gray-20: #CCCCCC;
  --gray-10: #E6E6E6;
  --gray-5: #F0F0F0;
  --gray-4: #F3F3F3;
  --gray-3: #F6F6F6;
  --gray-2: #F8F8F8;
  --gray-1: #FCFCFC;
}