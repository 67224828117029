:root {
  --yellow-vivid-80: #422D19;
  --yellow-vivid-70: #5C4809;
  --yellow-vivid-60: #776017;
  --yellow-vivid-50: #947100;
  --yellow-vivid-40: #B38C00;
  --yellow-vivid-30: #DDAA01;
  --yellow-vivid-20: #FFCD07;
  --yellow-vivid-10: #FEE685;
  --yellow-vivid-5: #FFF5C2;
}