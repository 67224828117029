.gv-br-select {
  --input-button-margin: var(--spacing-scale-half);
  --input-padding: 0 var(--spacing-scale-2x);
  --input-padding-button: var(--spacing-scale-5x);
  --input-padding-icon: var(--spacing-scale-5x);
  --input-size: var(--input-medium);
  --input-small: 32px;
  --input-medium: 40px;
  --input-large: 48px;
  --input-highlight: 56px;
  --color: var(--color-light);
  --color-rgb: var(--color-light-rgb);
  --text-color: var(--color-light);
  --interactive: var(--interactive-light);
  --interactive-rgb: var(--interactive-light-rgb);
  --visited: var(--visited-light);
  --hover: var(--hover-light);
  --pressed: var(--pressed-light);
  --focus-color: var(--focus-color-light);
  --focus: var(--focus-color-light);
  color: var(--color);
  position: relative;
}